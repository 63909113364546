// Variable
$ie11Text: #001823;
$ie11Red: #de3518;
$ie11RedHover: #8a1500;

// Breakpoints
$max-sm: 639px;
$min-md: 640px;
$max-md: 991px;
$min-lg: 992px;
$max-lg: 1440px;
$min-xl: 1441px;

// IE11 modal - Not supported browser
.MuiDialog-paperWidthMd {
  @media (min-width: $min-lg) {
    max-width: 750px !important;
  }
}
#ie11NotSupported {
  z-index: 2300!important;
  .MuiDialogContent-root {
    padding: 0.4rem 0.6rem;
    @media (min-width: $min-md) {
      padding: 0.4rem 2.5rem;
    }
    @media (min-width: $min-lg) {
      padding: 0.4rem 7rem;
    }
  }
  .MuiPaper-root {
    text-align: center;
    padding: 1.25rem;
    border: 3px solid $ie11Red;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 1px 10px 15px rgba(0,0,0,.1);
  }
  #customized-dialog-title {
    padding: 0;
    .MuiButtonBase-root {
      position: absolute;
      right: .5rem;
      top: .5rem;
      svg.MuiSvgIcon-root {
        fill: $ie11Text;
        height: 1.2em;
        width: 1.2em;
      }
      &:hover {
        background-color: #fff;
      }
    }
  }
  svg path.fill-color {
    fill: #db3725;
  }
  h1.MuiTypography-h4 {
    font-weight: bolder;
  }
  .modal-logo {
    width: 80px;
    height: auto;
    margin: 0 auto 2rem;
  }
  .modal-text {
    font-size: 20px;
    margin: 1rem 0 2rem;
    line-height: 3rem;
    font-weight: 300;
  }
  .browser-label {
    color: $ie11Red;
    font-size: 18px;
    font-weight: 700;
    line-height: 2;
    white-space: nowrap;
    > svg {
      width: 20px;
      height: 20px;
      vertical-align: text-bottom;
      margin-left: 0.5rem;
      fill: $ie11Red;
    }
    &:hover {
      text-decoration: underline;
      color: $ie11RedHover;
    }
  }
  a.browser-icon {
    text-align: center;
    display: inline-block;
    margin: 0 0 2rem;
    text-decoration: none;
    @media (min-width: $min-md) {
      margin: 0;
    }
    > svg {
      width: 85px;
      height: 85px;
    }
  }
  .modal-footer-content {
    font-size: 2.3rem;
    margin: 1.5rem 0 2.5rem;
    padding-top: 1.8rem;
    border-top: 1px solid #5e7079;
  }
  a.call-us {
    text-decoration: none;
    color: $ie11Text;
  }
  .visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
}