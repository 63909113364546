// Variables
$mainFocusOutlineColor: #384f61;
$footerFocusOutlineColor: #000;
$sm: 599px;
$md: 959px;
$lg: 1279px;
$primary-color: #db3725;
$textColor: #001823;


// Mixins
// Keyboard modality focus outline
@mixin focusOutline($offset) {
  outline-style: solid;
  outline-width: 0.2rem;
  outline-offset: $offset;
}

html {
    /* see: https://material-ui.com/customization/typography/#font-size, this makes 1rem == 10px */
    font-size: 62.5%;
    height: 100%;
}

body {
  height: 100%;

  #root {

    margin: 0;
    font-family:
      -apple-system,
      BlinkMacSystemFont,
      "Roboto",
      "Segoe UI",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    display: flex;
    flex-direction: column;

    main {
      display: block;
      flex: 1 0 auto;
    }

    header, footer {
      flex-shrink: 0;
    }

    code {
      font-family:
        source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        "Courier New",
        monospace;
    }

    hr {
      border-style: solid;
      border-color: #d2d7d9;
    }

    .notes--bold {
      @extend .notes;
      font-weight: bold;
    }

    .notes {
      font-size: 1.2rem;
      color: #5E7079;
      a {
        color: #5E7079;
      }
    }

    .notes--largeSize {
      font-size: 1.9rem;
      color: #070707;
      a {
        color: #070707;
      }
    }

    // LINKS
    // link in a paragraph, sentence
    .textLink {
      text-decoration: underline;
      color: $textColor;
      &:hover, &:focus {
        color: $primary-color;
      }
    }

    // MUI overrides
    .MuiFormControl-root {
      width: 100%;
      padding: 1rem 0;
      margin-top: 0.4rem;
    }

    .MuiInputLabel-root {
      padding: 1rem 0;
    }

    .MuiOutlinedInput-root {
      border-radius: 0;
    }

    .MuiTypography-h1 {
      font-weight: 700;
      font-size: 5.6rem;
      margin-bottom: 3rem;
    }

    .MuiTypography-h2 {
      font-weight: 700;
      font-size: 2rem;
      margin-top: 4rem;
      margin-bottom: 1rem;
      line-height: 3rem;
    }

    .MuiInputBase-root.Mui-disabled{
      background-color: #F5F5F5;
    }

    .MuiInputBase-root.Mui-focused fieldset {
      border-color: #001823;
    }

    label.Mui-focused.MuiInputLabel-root,
    label.MuiFormLabel-filled {
      color: #001823;
    }

    .MuiTypography-body2 {
      font-size: 1.6rem;
      line-height: 1.6rem;
      letter-spacing: 0.2rem;
    }

    .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.7rem;
      margin-bottom: 1.4rem;
    }
    // End MUI overrides
  }

  // MUI Non-root overrides
  .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      font-size: 1.6rem;
      max-width: 42rem;
      background-color: #001823;
      a {
        color: #FFF;
      }
    }
  }
  // End MUI Non-root overrides
}

// Keyboard modality
body[modality="keyboard"] {
  #root {
    .UopDashboard,
    a:not(.MuiButtonBase-root) {
      &:focus,
      :focus {
        outline-color: $mainFocusOutlineColor;
        @include focusOutline(4px);
      }
    }
    [aria-labelledby="tableProgramSelection"] {
      &:focus {
        outline-color: $footerFocusOutlineColor;
        @include focusOutline(2px);
      }
    }
    .MuiTable-root {
      .MuiTableSortLabel-root {
        &:focus {
          outline-color: $footerFocusOutlineColor;
          @include focusOutline(2px);
        }
      }
    }
    > div > footer .MuiContainer-root a,
    > footer .MuiContainer-root a {
      &:focus {
        outline-color: $footerFocusOutlineColor;
        @include focusOutline(2px);
      }
    }
    button.MuiButtonBase-root:focus {
      border-radius: 0px;
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(2px);
    }
    a.MuiButtonBase-root:focus,
    a.MuiButtonBase-root.Mui-focusVisible {
      border-radius: 0px;
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(2px);
    }
    .MuiButtonBase-root.MuiRadio-root.Mui-focusVisible {
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(-3px);
    }
    .MuiSvgIcon-root:focus {
      border-radius: 0px;
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(2px);
    }
  }
  #ie11NotSupported a {
    &:focus,
    :focus {
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(0);
    }
  }
}
